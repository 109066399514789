<template>
  <div id="insurance_policy">
    <div v-if="bx">
      <div class="top">
        <p><svg-icon icon-class="bz" />国泰财产保险</p>
        <a href="https://www.cathay-ins.com.cn/"
          >点击查看官网<svg-icon icon-class="arrow_right"
        /></a>
      </div>
      <!-- http://www.cpic.com.cn/ -->
      <div class="box">
        <img src="@/assets/images/policy_logo.png" alt="" />
        <div>
          <p>保单状态</p>
          <!-- <p>{{ bx ? "已激活" : "暂无信息" }}</p> -->
          <p>已激活</p>
        </div>
        <div>
          <p>保障期限</p>
          <!-- <p>2022.04.18-2023.04.17</p> -->
          <p>{{ bx.insurerSignDate }}-{{ bx.insurerSignDate2 }}</p>
        </div>
      </div>
      <div class="box">
        <div>
          <p>被保商品</p>
          <!-- <p>正山堂特制金骏眉红茶</p> -->
          <p>{{ bx.insuredCommodity }}</p>
        </div>
        <div>
          <p>被保商品码</p>
          <!-- <p>83736464643334131122</p> -->
          <p>{{ bx.commodityCode }}</p>
        </div>
        <div>
          <p>保单凭证号</p>
          <!-- <p>#878510213189</p> -->
          <p>{{ bx.policyNumber }}</p>
        </div>
        <div>
          <p>投保公司</p>
          <!-- <p>深圳凯华技术有限公司（正品汇）</p> -->
          <p>{{ bx.insurerCompany }}</p>
        </div>
        <div>
          <p>承保公司</p>
          <p>国泰财产保险有限责任公司</p>
        </div>
        <!-- <div>
          <p>理赔服务</p>
          <a href="http://www.cpic.com.cn/">白鸽陪您行</a>
        </div> -->
      </div>
      <div class="attachment" v-if="bx.policyAttachment">
        <img :src="bx.policyAttachment" />
      </div>
      <div class="process">
        <img src="@/assets/images/policy_lc.png" alt="" />
      </div>
    </div>
    <none v-else />
  </div>
</template>

<script>
import { formatDay3 } from "@/utils/validate";
import none from "@/components/none.vue";
export default {
  name: "insurance-policy",
  components: {
    none,
  },
  data() {
    return {
      bx: "",
    };
  },

  created() {
    this.api();
  },

  methods: {
    async api() {
      let goods = JSON.parse(sessionStorage.getItem("goods"));
      // console.log(goods);
      //保险
      const data = await this.$API.trackBack.getInsuranceDetails({
        object: {
          commodityCode: goods.barcode,
          enterpriseId: goods.enterpriseId,
          // commodityCode: "6922266448849",
          // enterpriseId: "5ADC005040714102863B7A13F34A1789",
        },
        pageNum: 0,
        pageSize: 0,
      });
      if (data.data.data) {
        this.bx = data.data.data;
        this.bx.insurerSignDate2 = formatDay3(
          this.bx.insurerSignDate + 31536000000
        );
        this.bx.insurerSignDate = formatDay3(this.bx.insurerSignDate);
        console.log("保险", this.bx);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#insurance_policy {
  padding: 15px 12px;
  font-size: 14px;
  height: 100%;
  background-color: #f5f5f5;
  .top {
    height: 46px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px 0 13px;
    margin-bottom: 12px;
    p {
      font-size: 15px;
      color: #333333;
    }
    a {
      font-size: 14px;
      color: #888888;
      .svg-icon {
        margin-left: 6px;
      }
    }
  }
  .box {
    background: #fff;
    border-radius: 8px;
    padding: 18px 12px;
    position: relative;
    margin-bottom: 12px;
    // display: flex;
    div::after {
      display: table;
      clear: both;
      content: "";
    }
    div {
      line-height: 16px;
      margin-bottom: 10px;
      color: #333333;
      p:first-child {
        width: 96px;
        color: #888888;
      }
      p {
        float: left;
      }
      a {
        color: #0787f4;
        text-decoration: underline;
      }
    }
    div:last-child {
      margin: 0;
    }
    img {
      width: 58px;
      position: absolute;
      top: 10px;
      right: 12px;
    }
  }
  .attachment {
    background: #fff;
    border-radius: 8px;
    padding: 18px 12px;
    margin-bottom: 12px;
    img {
      width: 100%;
    }
  }
  .process {
    background: #fff;
    border-radius: 8px;
    img {
      width: 100%;
    }
  }
  .none {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
      position: relative;
      width: 270px;
      height: 250px;
      text-align: center;
      img {
        width: 100%;
      }
      p {
        position: absolute;
        font-size: 15px;
        color: #b1b1b1;
        top: 192px;
        width: 100%;
      }
    }
  }
}
</style>
